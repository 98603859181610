import { ref, Ref, readonly } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import {
    Note,
    FeedbackNote,
    FeedbackNoteType,
} from '@/models/users/uploads/note'

const debug = false

export type NoteOptions = Omit<Note, 'id' | 'createdAt'>

export type SeriesDataItem = {
    x: string
    y: Array<number | undefined>
    z: {
        desc: string
    }
    fillColor: string
    label: {
        text: string
    }
}

/** The 'initBuffer' and 'finalBuffer' are additional times added to the beginning and end of a note, respectively.
These buffer times are customizable for each type of note, allowing users to see some previous and subsequent content
or statements related to the note. */
export const notesDictionary: {
    [key in FeedbackNoteType]?: {
        colors: string[]
        initBuffer?: number
        finalBuffer?: number
    }
} = {
    [FeedbackNoteType.Engagement]: {
        initBuffer: 10,
        finalBuffer: 10,
        colors: ['#00E396'],
    },
    [FeedbackNoteType.Disengagement]: {
        colors: ['#FF4560'],
        initBuffer: 10,
        finalBuffer: 10,
    },
    [FeedbackNoteType.OpenEndedQuestion]: {
        colors: ['#008FFB'],
        initBuffer: 5,
        finalBuffer: 10,
    },
    [FeedbackNoteType.Affirmation]: {
        colors: ['#FEB019'],
        initBuffer: 5,
        finalBuffer: 10,
    },
    [FeedbackNoteType.Reflection]: {
        colors: ['#775DD0'],
        initBuffer: 10,
        finalBuffer: 10,
    },
    [FeedbackNoteType.EngagementDisengagement]: {
        colors: ['#00E396', '#FF4560'],
    },
}

const uploadNotes: Ref<Note[]> = ref([])

export default function (uploadId?: string, userId?: string) {
    const { viewer } = useViewer()

    async function fetchUploadNotes() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (uploadId === undefined) throw 'Invalid upload ID'

        const userDocId = userId ?? viewer.value.id

        const notesSnapshot = await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .collection('notes')
            .get()

        uploadNotes.value = getCollectionDocs<Note>(notesSnapshot)
    }

    async function addNote(note: NoteOptions) {
        logger(debug, note)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (uploadId === undefined) throw 'Invalid upload ID'

        const userDocId = userId ?? viewer.value.id

        await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .collection('notes')
            .add({
                ...note,
                createdAt: serverTimestamp(),
            })

        await fetchUploadNotes()
    }

    async function deleteNote(noteId: string) {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (uploadId === undefined) throw 'Invalid upload ID'

        const userDocId = userId ?? viewer.value.id

        await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .collection('notes')
            .doc(noteId)
            .delete()

        await fetchUploadNotes()
    }

    async function updateNote(noteId: string, updatedNote: Note) {
        logger(debug, noteId)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (uploadId === undefined) throw 'Invalid upload ID'

        const userDocId = userId ?? viewer.value?.id

        await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .collection('notes')
            .doc(noteId)
            .update({
                ...updatedNote,
                updatedAt: serverTimestamp(),
                updatedBy: viewer.value.id,
            })

        await fetchUploadNotes()
    }

    function fillGaps(
        intervals: FeedbackNote[],
        videoDuration: number
    ): FeedbackNote[] {
        if (intervals.length === 0) {
            return []
        }

        // Sort intervals by start time
        intervals.sort((a, b) => (a.range?.start || 0) - (b.range?.start || 0))

        const filledIntervals: FeedbackNote[] = []
        let lastEnd = 0

        // Handle the case where the first interval does not start at 0
        if ((intervals[0].range?.start || 0) > 0) {
            filledIntervals.push({
                ...intervals[0],
                range: { start: 0, end: intervals[0].range?.start || 0 },
            })
            lastEnd = intervals[0].range?.start || 0
        }

        intervals.forEach((interval, index) => {
            // Fill any gap before this interval
            const intervalStart = interval.range?.start || 0
            if (lastEnd < intervalStart) {
                filledIntervals.push({
                    ...intervals[index - 1 >= 0 ? index - 1 : index],
                    range: { start: lastEnd, end: intervalStart },
                })
            }

            // Add the current interval
            filledIntervals.push(interval)

            lastEnd = interval.range?.end || lastEnd
        })

        // Extend the last interval to the end of the video if necessary
        if (lastEnd < videoDuration) {
            filledIntervals.push({
                ...intervals[intervals.length - 1],
                range: { start: lastEnd, end: videoDuration },
            })
        }

        return filledIntervals
    }

    /**
     *
     * Consolidates a given array of Note objects (intervals)
     * by merging overlapping or adjacent intervals that have the
     * same text content.
     */
    function consolidateIntervals(intervals: FeedbackNote[]): FeedbackNote[] {
        const consolidated: FeedbackNote[] = []

        intervals.forEach((interval) => {
            if (consolidated.length === 0) {
                consolidated.push({ ...interval })
            } else {
                const lastInterval = consolidated[consolidated.length - 1]

                if (
                    lastInterval.text === interval.text &&
                    lastInterval.range &&
                    interval.range &&
                    lastInterval.range.end >= interval.range.start
                ) {
                    consolidated[consolidated.length - 1] = {
                        ...lastInterval,
                        range: {
                            ...lastInterval.range,
                            end: Math.max(
                                lastInterval.range.end,
                                interval.range.end
                            ),
                        },
                    }
                } else {
                    consolidated.push({ ...interval }) // Create a copy of the interval
                }
            }
        })

        return consolidated
    }

    function deinitUploadNotes() {
        logger(debug)
        uploadNotes.value = []
    }

    return {
        uploadNotes: readonly(uploadNotes),

        addNote,
        consolidateIntervals,
        deinitUploadNotes,
        deleteNote,
        fetchUploadNotes,
        fillGaps,
        updateNote,
    }
}
